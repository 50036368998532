import radixStyles from "@radix-ui/themes/styles.css?url";
import globalStyles from "./globals.css?url";
import "@vendrinc/frontend-ts/theme.css";
import "@vendrinc/frontend-ts/style.css";
import * as Sentry from "@sentry/remix";

import NProgress from "nprogress";
import { Theme, Flex, Heading, Text } from "@radix-ui/themes";
import type {
  LoaderFunctionArgs,
  HeadersFunction,
  LinksFunction,
} from "@remix-run/node";
import { useEffect, useMemo } from "react";
import {
  Links,
  useRouteLoaderData,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
  useFetchers,
  useNavigation,
  useLocation,
  MetaFunction,
} from "@remix-run/react";
import { useSpinDelay } from "spin-delay";
import { ColorSchemeScript, useColorScheme } from "~/utils/color-scheme";
import { parseColorScheme } from "~/lib/color-scheme.server";
import {
  withSentry,
  SentryMetaArgs,
  captureRemixErrorBoundaryError,
} from "@sentry/remix";
import { getUserSession } from "~/lib/session-utils.server";
import { getToast } from "~/lib/toast.server";
import { combineHeaders } from "~/lib/utils";
import { Toaster, useToast } from "./components/Toaster";
import { setSentryTransactionName } from "./utils/sentry";

const { getConfig } = await import("~/lib/config.server");
const dopplerConfig = await getConfig;

NProgress.configure({ showSpinner: false });

export const headers: HeadersFunction = () => {
  return {
    robots: "noindex",
  };
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: radixStyles },
  { rel: "stylesheet", href: globalStyles },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const colorScheme = await parseColorScheme(request);
  const { toast, headers: toastHeaders } = await getToast(request);
  Sentry.getCurrentScope().setTag("url", request.url);

  return Sentry.withScope(async (scope) => {
    setSentryTransactionName(request);
    const user = await getUserSession(request);
    scope.setUser({
      id: user?.sub,
      email: user?.email,
    });
    return Response.json(
      {
        colorScheme,
        toast,
        ENV: {
          CI: process.env.CI ?? false,
          SEGMENT_WRITE_KEY: dopplerConfig.SEGMENT_WRITE_KEY ?? "",
          SENTRY_DSN: dopplerConfig.SENTRY_DSN || "",
          SENTRY_SAMPLE_RATE: dopplerConfig.SENTRY_SAMPLE_RATE || 0.2,
          ENVIRONMENT: process.env.ENV,
        },
      },
      {
        headers: combineHeaders(
          {
            Vary: "Cookie",
          },
          toastHeaders,
        ),
      },
    );
  });
}

interface DocumentProps {
  title?: string;
  children: React.ReactNode;
}

function Document({ children, title }: DocumentProps) {
  const data = useRouteLoaderData<typeof loader>("root");
  const colorScheme = useColorScheme();
  useToast(data?.toast);

  const navigation = useNavigation();
  const fetchers = useFetchers();

  /**
   * This gets the state of every fetcher active on the app and combine it with
   * the state of the global transition (Link and Form), then use them to
   * determine if the app is idle or if it's loading.
   * Here we consider both loading and submitting as loading.
   */
  let state = useMemo<"idle" | "loading">(
    function getGlobalState() {
      let states = [
        navigation.state,
        ...fetchers.map((fetcher) => fetcher.state),
      ];
      if (states.every((state) => state === "idle")) return "idle";
      return "loading";
    },
    [navigation.state, fetchers],
  );

  const showSpinner = useSpinDelay(state !== "idle", {
    delay: 250,
    minDuration: 200,
  });

  useEffect(() => {
    // and when it's something else it means it's either submitting a form or
    // waiting for the loaders of the next location so we start it
    if (showSpinner) NProgress.start();
    // when the state is idle then we can to complete the progress bar
    if (!showSpinner) NProgress.done();
  }, [showSpinner]);

  return (
    <html lang="en" className={colorScheme}>
      <head>
        <ColorSchemeScript />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {title && <title data-title-override="">{title}</title>}
      </head>
      <body>
        <Theme accentColor="iris" id="root-theme">
          {children}
          <ScrollRestoration />
          {data && (
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
              }}
            />
          )}
          <Scripts />
        </Theme>
        <Theme className="z-10 absolute">
          <Toaster position="bottom-right" theme={colorScheme} />
        </Theme>
      </body>
    </html>
  );
}

export function App() {
  return (
    <Document>
      <Outlet />
    </Document>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const location = useLocation();

  if (isRouteErrorResponse(error)) {
    if (error.status !== 404) {
      //We dont want to push 404 errors to Sentry
      const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
      Sentry.getCurrentScope().setTag("url", fullUrl);
      captureRemixErrorBoundaryError(error);
    }
    return (
      <Document>
        <Flex
          minHeight="200px"
          direction="column"
          gap="3"
          align="center"
          justify="center"
        >
          <Heading size="6">
            {error.status} {error.statusText}
          </Heading>
          <Text>{error.data}</Text>
        </Flex>
      </Document>
    );
  }

  if (isRouteErrorResponse(error)) {
    return (
      <Document>
        <Flex
          minHeight="200px"
          direction="column"
          gap="3"
          align="center"
          justify="center"
        >
          <Heading size="6">
            {error.status} {error.statusText}
          </Heading>
          <Text>{error.data}</Text>
        </Flex>
      </Document>
    );
  }

  console.error(error);

  return (
    <Document>
      <Flex
        minHeight="200px"
        direction="column"
        gap="3"
        align="center"
        justify="center"
      >
        <Heading size="6">Error</Heading>
        <Text>Something went wrong! Please try again later.</Text>
      </Flex>
    </Document>
  );
}

export const meta = ({ data }: SentryMetaArgs<MetaFunction<typeof loader>>) => {
  if (!data) return [];
  return [
    {
      name: "sentry-trace",
      content: data.sentryTrace,
    },
    {
      name: "baggage",
      content: data.sentryBaggage,
    },
  ];
};

export default withSentry(App);
